import { template, htmlDecode } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import { assignAtomicInfo, handleParams, setPretreatInfo } from './tools'
import { abtservice } from 'public/src/services/abt'
import { addWishGoodsApi, wishlistDelApi } from 'public/src/services/api/user/wishlist.js'

class ItemInterfaceSer {
  constructor () {
    if (typeof window === 'undefined') return

    this.init()
  }

  async init () {
    this.language = await i18n.loadByQueue('item')
  }

  /************* 静态展示接口 *************/
  /**
   * 商品项服务接口
   * @param {Array} goods 商品列表
   * @param {Object} fields 请求颗粒
   *   @param {Boolean} mallInfo mall相关信息
   *   @param {Boolean} productInfo 详细信息，基本涵盖了商品所有的基础信息 - 单个
   *   @param {Boolean} baseInfo 基础信息（goods_name，goods_id, goods_img等）- 批量
   *   @param {Boolean} prices 价格
   *   @param {Boolean} skuPrices sku价格
   *   @param {Boolean} realTimePricesWithPromotion 价格、促销（实时）
   *   @param {Boolean} vipDiscountPrices vip专属价格
   *   @param {Boolean} sheinClubPromotionInfo 付费会员促销信息
   *   @param {Boolean} promotion 促销
   *   @param {Boolean} topPick romwe originals原创角标
   *   @param {Boolean} stock 库存（总库存，is_on_sale在售状态）
   *   @param {Boolean} sellingPoints 卖点
   *   @param {Boolean} relatedProducts 色块商品（包含主商品）
   *   @param {Boolean} relatedProductsOnly 色块商品（不包含主商品）
   *   @param {Boolean} soldOutColors 售罄色块
   *   @param {Boolean} videoIcon 视频图标（目前只有romwe展示）
   *   @param {Boolean} detailImage 商品多张图片（需要二图时）
   *   @param {Boolean} cccTspBadges ccc-tsp角标
   *   @param {Boolean} wish 收藏状态
   *   @param {Boolean} seriesAndBrand 品牌系列
   *   @param {Boolean} plusSize 大码系列角标
   *   @param {Boolean} tspLabels tsp各种角标
   *   @param {Boolean} catName 分类名称
   *   @param {Boolean} saleAttrList 销售属性（尺码等）
   *   @param {Boolean} newFlashPromotion 新型闪购促销信息（新人，专属）
   *   @param {Boolean} newProductUnsale 预售（coming soon）
   *   @param {Object} realTimeTspLabels 实时tsp标签 格式：{ label_cloud: ['60006672'], machine_label: ['xxx'] }
   *   @param {Boolean} quickShip 快速发货
   *   @param {Boolean} promoLabel 大促角标（黑五等）
   *   @param {Boolean} userBehaviorLabel 用户标签   
   *   @param {Boolean} userCommentKeywordsLabel 用户评论关键词标签
   *   @param {Boolean} beltLabel 腰带
   *   @param {Boolean} rankingList 榜单
   *   @param {Boolean} estimatedPrice 到手价
   * @param {String} pageKey 页面key
   * @param {String} subPageKey 页面子key（推荐）
   * @param {String} sceneKey, ccc-tsp角标的场景key
   * @param {Boolean} needAssign 整合数据（将请求的每个颗粒以goods_id为键混入）
   * @param {String} caller 调用方。目前用于区分是否走abt
   * @param {Boolean} isPaid 是否是付费会员
   * @param {Object} cccParams ccc需要的参数
   * @param {Object} pricesWithPromotionSpecialSceneType 屏蔽ab价
   *   @param {String} cateId cateType=0或不传时，这个值传分类id/选品id，cateType=3时，这个值传goodsSn
   *   @param {Number} cateType 0 所有分类，1 频道，2 专题标识符，3 sku，4 真实分类，5 选品分类，6 标签，7 搜索词类型
   *   @param {String} realCateId 商详的真实分类id
   * 
   */
  async getAtomicInfo ({ goods, fields = {}, needAssign = true, pageKey, sceneKey, subPageKey, cb, caller, cccParams, isPaid, pricesWithPromotionSpecialSceneType }) {
    const { reqParams, paramsErr } = handleParams({ goods, fields, pageKey, subPageKey, sceneKey, caller, cccParams, isPaid, pricesWithPromotionSpecialSceneType })

    if (paramsErr || reqParams.fields && Object.keys(reqParams.fields).length === 0) return needAssign ? [] : {}

    const { data } = await schttp({
      method: 'POST',
      url: '/api/productAtom/atomicInfo/get',
      data: reqParams
    })

    if (needAssign) return assignAtomicInfo({ goods, atomicInfo: data || {}, cb })

    return data
  }

  // 获取原子信息（新）-- 除了促销预处理在客户端，其他数据预处理都在服务端
  async getAtomicInfoNew({ goods, fields = {}, pageKey, sceneKey, subPageKey, cb, caller, cccParams, isPaid, mallInfo, abtInfo }) {
    const { reqParams, paramsErr } = handleParams({ goods, fields, pageKey, subPageKey, sceneKey, caller, cccParams, isPaid, mallInfo, abtInfo })

    if (paramsErr || reqParams.fields && Object.keys(reqParams.fields).length === 0) return []
    
    const { data } = await schttp({
      method: 'POST',
      url: '/api/productAtom/atomicInfoWithDeal/get',
      data: reqParams
    })

    return assignAtomicInfo({ goods, atomicInfo: data || {}, cb })
  }

  /************* 主动操作的接口 *************/
  /**
   * 加车接口
   * @param {String|Number} goodsId
   * @param {String} mallCode
   * @param {Number} quantity
   * @param {Object} traceId
   * @param {Object} promotionId
   * @param {Object} promotionType
   * @param {String} skuCode
   * @returns {Promise}
   */
  async addToBag (payload) {
    const { goodsId = '', mallCode = '', quantity = 1, traceId = '', promotionId = '', promotionType = '', skuCode = '' } = payload || {}

    // 请求入参
    const requestData = {
      goods_id: goodsId,
      mall_code: mallCode,
      quantity
    }
    if (skuCode) requestData.sku_code = skuCode
    if (traceId) requestData.trace_id = traceId
    if (promotionId) requestData.promotion_id = promotionId
    if (promotionType) requestData.promotion_type = promotionType

    let result = null

    try {
      result = await schttp({
        method: 'POST',
        url: '/api/cart/add_mall/create',
        data: requestData
      })
    } catch(e) {
      console.log('add to bag error:', e)
    }

    if (!result) return Promise.resolve({ code: -1, msg: 'request err' })

    let tips = null
    if (result.code == 0) {
      window.cart_module?.recart(result)
      window.cartVue?.updateInfo(result.info)
      tips = this.language.SHEIN_KEY_PC_15800
    } else {
      switch(Number(result.code)) {
        // 超出限购数量
        case 200401:
        case 500301:
          tips = this.language.SHEIN_KEY_PC_14661
          break
        // 售罄
        case 300402:
          tips = this.language.SHEIN_KEY_PC_14617
          break
        // 购物车达到上限（弹窗提示）
        case 300407:
          tips = null
          break  
        // 清仓限购
        case 300405:
          tips = htmlDecode({ text: this.language.SHEIN_KEY_PC_14560 })
          break  
        // 单个闪购商品超限
        case 500302:
          tips = template(result.info?.limitCount || 0, htmlDecode({ text: this.language.SHEIN_KEY_PC_14560 }))
          break
        // 单个用户可加购的全部闪购商品超限
        case 300417:
        case 500303:
          tips = template(result.info?.limitCount || 0, result.tips)
          break
        case 500304:
          tips = this.language.SHEIN_KEY_PC_17492
          break
        case 500305:
          tips = this.language.SHEIN_KEY_PC_17494
          break
        default:
          tips = this.language.SHEIN_KEY_PC_14605
      }
    }
    tips && Object.assign(result, { tips })

    return Promise.resolve(result)
  }

  /**
   * 收藏接口
   * @param {Object} goodsItem 当前收藏项数据
   * @param {Object} params 接口入参
   *   @param {Number} goodsId
   *   @param {Object} sizeInfo 选中的尺码
   * @param {Boolean} config 配置
   *   @param {Boolean} showAddToBoard 是否展示加组弹窗
   *
   */
  async addWish ({ goodsItem, params: { goodsId = '', goodsSn = '', sizeInfo = {}, mallCode = '' }, config = { showAddToBoard: true } }) {
    if (!goodsItem || !goodsId) return console.warn('addWish miss params')

    const returnInfo = {}
    const { showAddToBoard } = config

    setPretreatInfo({ goodsItem: goodsItem, field: 'addWishlistResult', value: 0 })
    setPretreatInfo({ goodsItem: goodsItem, field: 'addWishlistGroup', value: 0 })

    const attrInfo = await this.getAttrSizeInfo({ goodsItem }) // 拿销售属性，如果为无销售属性商品，收藏时需要传skuCode

    const params = { goods_id: goodsId, skc_name: goodsSn }
    if (sizeInfo?.attr_value_id) {
      Object.assign(params, { attrValueId: sizeInfo.attr_value_id })
    }
    if (attrInfo?.skuCode) {
      Object.assign(params, { sku_code: attrInfo.skuCode })
    }
    if (mallCode) {
      Object.assign(params, { mall_code: mallCode })
    }

    // 请求队列
    const requestQueue = [addWishGoodsApi(params)]
    // 显示加组弹窗
    if (showAddToBoard) {
      requestQueue.push(schttp({ url: '/api/user/wishlist/group/optional/goods/get' }))
      requestQueue.push(abtservice.getUserAbtResult({ posKeys: 'AddShowGroup' }))
    }
    const [addWishlistResult, addWishlistGroupResult, abt] = await Promise.all(requestQueue) || []

    returnInfo['responseData'] = {
      addWishlistResult,
      addWishlistGroupResult
    }

    // 成功和失败 数据格式不一致
    if (addWishlistResult?.data && (addWishlistResult.data.code == 0 || addWishlistResult.data.code == 400401)) {
      setPretreatInfo({ goodsItem: goodsItem, field: 'addWishlistResult', value: 1 })
      returnInfo['type'] = 'success'

      //收藏成功后，是否展示加组弹窗
      if (showAddToBoard && addWishlistGroupResult) {
        const groupList = addWishlistGroupResult.data?.info?.groupList || []
        const { AddShowGroup = {} } = abt || {}
        const showBoard = AddShowGroup.param === 'ShowGroup' || groupList.length  // 实验控制是否显示board
        // const showBoard = true  // 实验控制是否显示board
        if (showBoard) {
          setPretreatInfo({ goodsItem: goodsItem, field: 'addWishlistGroup', value: true })
          setTimeout(() => {
            setPretreatInfo({ goodsItem: goodsItem, field: 'addWishlistGroup', value: false })
          }, 5000)
          returnInfo['showWishlistGroup'] = 1
        }
      }
    } else if (addWishlistResult?.data?.code == 400427) { // 超出收藏限制
      returnInfo['type'] = 'limit'
      returnInfo['tips'] = template(addWishlistResult.data.info.limit, this.language.SHEIN_KEY_PC_19347)
    } else if (addWishlistResult?.code === -1) {
      returnInfo['type'] = 'needLogin'
    }

    return returnInfo
  }

  /**
   * 取消收藏
   * @param {Object} goodsItem 当前移除的收藏项数据
   * @param {Boolean} showGroupPopup 是否展示加组弹窗
   * @param {Object} params 接口入参
   *   @param {Number} goodsId
   * @returns {Object}
   */
  async cancelWish ({ goodsItem, showGroupPopup = true, params: { goodsId = '' } }) {
    if (!goodsItem || !goodsId) return console.warn('cancelWish miss params')

    const returnInfo = {}

    // 请求锁
    if (goodsItem?.pretreatInfo?.cancelWishlistLoading) {
      returnInfo['type'] = 'requesting'
      return returnInfo
    }

    setPretreatInfo({ goodsItem: goodsItem, field: 'cancelWishlistLoading', value: true })

    const res = await wishlistDelApi({ goods_id: goodsId })

    returnInfo['responseData'] = res
    setPretreatInfo({ goodsItem: goodsItem, field: 'cancelWishlistLoading', value: false })
    if (Number(res.data?.code) === 0 && Number(res.data?.info?.result) === 1) {
      setPretreatInfo({ goodsItem: goodsItem, field: 'addWishlistResult', value: 0 })
      returnInfo['type'] = 'success'
    } else {
      returnInfo['type'] = 'fail'
    }

    if (showGroupPopup) {
      setPretreatInfo({ goodsItem: goodsItem, field: 'addWishlistGroup', value: 0 })
    }

    return returnInfo
  }

  /**
   * 销售属性，尺码信息
   * @param {Object} goodsItem 商品数据 
   * @returns 
   */
  async getAttrSizeInfo ({ goodsItem = null }) {
    if (!goodsItem) return
    let returnInfo = {}
    let goodsId = goodsItem.goods_id
    setPretreatInfo({ goodsItem, field: 'getAttrSizeInfoLoading', value: true })

    const getAttrSize = schttp({
      url: '/api/productInfo/attr/get',
      params: { id: goodsId, _t: Date.now()  }
    })

    const getSizeRules = schttp({
      url: '/api/productInfo/sizeRules/get',
      params: { goods_ids: goodsId }
    })

    const results = await Promise.all([getAttrSize, getSizeRules])
    let attrSize = results?.[0] || {}
    let sizeRules = results?.[1]?.info?.[Number(goodsId)] || {}
    if (attrSize && Number(attrSize.code) === 0) {
      if (attrSize.info?.attrSize?.length > 0) { // 老尺码有数据
        // 有尺码, 尺码有价格
        attrSize = (attrSize.info.attrSize || []).filter((size) => size?.price?.salePrice && size?.price?.retailPrice)
        // 预处理折扣信息
        attrSize.forEach((item) => {
          // 构造数据
          item['goodsInfoForSize'] = {}
          item['goodsInfoForSize']['retailPrice'] = item.price.retailPrice
          item['goodsInfoForSize']['salePrice'] = item.price.salePrice
          item['goodsInfoForSize']['unit_discount'] = item.price.unit_discount
          item['goodsInfoForSize']['original_discount'] = goodsItem?.original_discount
          item['goodsInfoForSize']['pretreatInfo'] = {}
          item['goodsInfoForSize']['pretreatInfo']['limitedoff'] = goodsItem?.pretreatInfo?.limitedoff || undefined
          this.dealUnitDiscount({ goodsItem: item.goodsInfoForSize })
        })
  
        setPretreatInfo({ goodsItem, field: 'attrSize', value: attrSize })
        setPretreatInfo({ goodsItem, field: 'sizeRules', value: sizeRules })
      } else if (attrSize.info?.sale_attr_list?.[goodsId]) { // 新销售属性
        const saleAttrList = attrSize.info.sale_attr_list[goodsId]
        const skcSaleAttr = saleAttrList.skc_sale_attr
        const skuList = saleAttrList.sku_list || []
        // 没有次级销售属性，但会有一个默认的sku_code，sku_code作为入参直接加车
        if (!skcSaleAttr?.length && skuList.length == 1) {
          returnInfo = { goodsItem, skuCode: skuList[0].sku_code, hasAttr: false }
        }
        
        // 有次级销售属性
        if (skcSaleAttr.length) {
          setPretreatInfo({ goodsItem, field: 'attrSize', value: skcSaleAttr })
        }
      } else { // 兜底
        returnInfo = { goodsItem, selectedSize: '', hasAttr: false }
      }
    } else if (attrSize && Number(attrSize.code) == 200302) {
      // 没有尺码，直接加车
      returnInfo = { goodsItem, selectedSize: '', hasAttr: false }
    }

    setPretreatInfo({ goodsItem, field: 'getAttrSizeInfoLoading', value: false })
    return returnInfo
  }
}

export default new ItemInterfaceSer()
