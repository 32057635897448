import schttp from 'public/src/services/schttp'

export const wishlistListApi = (params) => {
  return schttp({
    url: '/api/user/wishlist/filter/list/get',
    method: 'get',
    params
  })
}

// 获取筛选收藏列表接口：（中间层融合BFF接口）
export const getBffWishProductsApi = async (params) => {
  return schttp({
    url: '/user/get_wishlist_by_filter',
    method: 'get',
    params,
    useBffApi: true
  })
}

// 获取筛选收藏的Filter参数列表接口：（中间层融合BFF接口）
export const getBffWishFiltersApi = async (params) => {
  return schttp({
    url: '/user/get_wishlist_filter',
    method: 'get',
    params,
    useBffApi: true
  })
}

// 获取筛选收藏的付费会员信息接口：（中间层融合BFF接口）
export const getBffWishSheinClubApi = async (params) => {
  return schttp({
    url: '/user/get_wishlist_uvip_discount',
    method: 'get',
    params,
    useBffApi: true
  })
}

export const wishlistDelApi = (params) => {
  return schttp({
    url: '/api/user/wishlist/del',
    method: 'get',
    params
  })
}

export const wishlistRemoveBatchApi = (params) => {
  return schttp({
    url: '/api/user/wishlist/batch/remove',
    method: 'get',
    params
  })
}

export const addWishGoodsApi = (data) => {
  return schttp({
    url: '/api/user/wishlist/add',
    method: 'post',
    data,
  })
}

export const getShareGroupTokenApi = (params) => {
  return schttp({
    url: '/user/wishlist/getEncryptToken',
    method: 'post',
    data: params,
    useBffApi: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getSheinClubApi = (isBff = false) => {
  return isBff ? schttp({
    url: '/user/get_wishlist_uvip_discount',
    method: 'get',
    params: {},
    useBffApi: true
  }) : schttp({
    url: '/api/user/wishlist/sheinClub/get',
    method: 'get',
    params: {},
  })
}
