import { promotionCenter } from 'public/src/pages/common/promotion'

const setPretreatInfo = ({ productItem = null, key = null, value = null } = {}) => {
  if (!productItem || !key) return
  if (!productItem.pretreatInfo) {
    productItem.pretreatInfo = {}
  }
  productItem.pretreatInfo[key] = value
}

// set服务类标签 中的 促销标签
const setPromotionLabel = ({ productItem, mixPromotionInfo } = {}) => {
  const set = ({ serviceLabels }) => {
    const promotionLabel = mixPromotionInfo[0]?.lable
    const promotionLabelIndex = serviceLabels.findIndex(item => item.promotionLabel)
    if (promotionLabelIndex > -1) {
      if (promotionLabel) {
        serviceLabels[promotionLabelIndex].promotionLabel = promotionLabel
      } else {
        serviceLabels.splice(promotionLabelIndex, 1)
      }
    }
  }

  set({ serviceLabels: productItem?.pretreatInfo?.pcStandardView?.serviceLabels || [] }) // 双图服务类标签
  set({ serviceLabels: productItem?.pretreatInfo?.pcBroadwiseView?.serviceLabels || [] }) // 单图服务类标签
}

// 促销 TODO: 全量切新商卡后，promotionReady可删除
const setPromotionInfo = ({ products = [], promotionInfoFromServer = {}, language = {} } = {}) => {
  promotionCenter.assignItemsSync({ 
    items: products, 
    isGetLable: true, 
    isAppPromotion: true, 
    promotionInfoFromServer,
    language
  })

  products.forEach(productItem => {
    const promotionInfo = productItem.promotionModule.rawModule || []
    const mixPromotionInfo = promotionInfo.filter(item => {
      if (item.typeId) {
        // 31品类限定商家限时直降 wiki.pageId=1198803653
        if (+item.typeId === 31) {
          return item.promotion_logo_type === 3
        } else if ([3, 10, 11].includes(+item.typeId)) { // 3: 限时折扣 10: 闪购 11: 直播闪购
          return true
        } else if (item.lable) {
          return true
        }
      }
      return false
    }) || []
    setPromotionLabel({ productItem, mixPromotionInfo })
    setPretreatInfo({ productItem, key: 'mixPromotionInfo', value: mixPromotionInfo })
    setPretreatInfo({ productItem, key: 'promotionReady', value: true })
  })
}

// TODO: 待接入最近浏览 再加上
// price cut
// const dealPriceCut = ({ productItem }) => {
//   if (!productItem) {
//     setPretreatInfo({ productItem, key: 'priceCutReady', value: true })
//     return
//   }

//   const { expired_salePrice, salePrice } = productItem // expired_salePrice过期价格, salePrice当前价格

//   const salePriceDelta = Number(expired_salePrice?.usdAmount || 0) - Number(salePrice?.usdAmount || 0)

//   if (salePriceDelta > 0) {
//     setPretreatInfo({ productItem, key: 'priceCut', value: salePriceDelta })
//   }

//   setPretreatInfo({ productItem, key: 'priceCutReady', value: true })
// }

// const setInfoByProductsLoop = ({ products }) => {
//   products.forEach(productItem => {
//     dealPriceCut({ productItem })
//   })
// }


const dealProductsPretreatInfo = ({ products, promotionInfoFromServer, language }) => {
  setPromotionInfo({ products, promotionInfoFromServer, language })

  return products
}

export {
  dealProductsPretreatInfo,
}
