/* eslint-disable require-atomic-updates */
/**
 * Promotion 促销中心
 */
import { PromotionUtils } from './utils/index'
import { Module } from './module'
import PROMOTION_CONFIG from './config'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import schttp from 'public/src/services/schttp'

const { NeedFetchPromotionTypeIds, FetchPromotionLableTypeIds } =
  PROMOTION_CONFIG // 需要请求详情的typeId

class PromotionCenter {
  constructor() {
    this.cache = {
      integrityPromotionInfo: {},
    }
    this.language = {}
  }

  /**
   * 通过商品列表获取promotion module
   * @param {Array} items [商品列表]
   * @param {Object} language [多语言]
   * @param {Object} isGetLable [获取促销标签]
   * @param {Object} isAppPromotion [app专享活动]
   * @param {Boolean} isGetVipPrice [获取会员价格]
   * @param {Boolean} isGetSheinClubPrice [付费会员促销信息]
   * @param {Boolean} isGetNewFlashPromotion [新型闪购促销信息（新人，专属）]
   */
  async assignItems({
    items = [],
    language = this.language || {},
    isGetLable = false,
    isGetVipPrice = false,
    isGetSheinClubPrice = false,
    isAppPromotion = false,
    isGetNewFlashPromotion = false,
    caller = '',
  } = {}) {
    if (!Object.keys(language).length) {
      this.language = language = await i18n.loadByQueue('promotion')
    }
    const goods = [].concat(items)
    const needRequestIds = []
    const asyncPromotionIds = isGetLable
      ? FetchPromotionLableTypeIds
      : NeedFetchPromotionTypeIds
    const goodsParams = []
    const flashPromotionParams = []
    let notAsyncPromotionintegrityPromotionInfo = {}
    let vipDiscountPrices = false
    let sheinClubPromotionInfo = false
    let newFlashPromotion = false

    // 遍历取出所有要请求详情的活动id，统一请求
    goods.forEach((item) => {
      item.promotionInfo &&
          item.promotionInfo.forEach((info) => {
            const { id, typeId, flash_type, flashType } = info
            const newFlashType = flash_type || flashType // 兼容驼峰下划线
            if (
              asyncPromotionIds.includes(+typeId) &&
              !needRequestIds.includes(id)
            ) {
              needRequestIds.push(id)
            }
            const { goods_id, goods_sn, mall_code } = item
            const param = { goods_id, goods_sn, mall_code }
            // 以下三个都是互斥的（有我而无他）
            if (isGetVipPrice && typeId == 12) {
              // s3 vip会员
              vipDiscountPrices = true
              param.promotionId = id
              goodsParams.push(param)
            } else if (isGetSheinClubPrice && typeId == 29) {
              // 付费会员
              sheinClubPromotionInfo = true
              param.promotionId = id
              goodsParams.push(param)
            } else if (
              isGetNewFlashPromotion &&
              typeId == 10 &&
              [2, 3, 4].includes(newFlashType && Number(newFlashType))
            ) {
              // 新型闪购（新人，专属）
              newFlashPromotion = true
              param.promotionId = id
              flashPromotionParams.push(param)
            }
          })

      if (isAppPromotion && item.appPromotion) {
        item.promotionInfo = item.promotionInfo.concat(item.appPromotion)
      }
    })

    const [vipPriceList, newFlashPromotionInfo] = await Promise.all([
      this.getVipPrice({
        goodsParams,
        vipDiscountPrices,
        sheinClubPromotionInfo,
        caller,
      }),
      this.getNewFlashPromotion({ flashPromotionParams, newFlashPromotion, caller }),
    ])

    let integrityPromotionInfo = await this.setIntegrityPromotionInfo({
      ids: needRequestIds,
    })
    integrityPromotionInfo = Object.assign(
      {},
      integrityPromotionInfo,
      notAsyncPromotionintegrityPromotionInfo
    )

    // 遍历为每个商品加上promotionModule | vipPrice | 付费会员促销信息
    goods.forEach((item) => {
      item.promotionModule = new Module({
        item,
        integrityPromotionInfo,
        language,
      })
      const vipPrices = vipPriceList?.[item.goods_id] || {}
      const exclusivePromotionPrice = item.exclusivePromotionPrice || vipPrices?.exclusivePromotionPrice
      const sheinClubPromotionInfo = item.sheinClubPromotionInfo || vipPrices?.sheinClubPromotionInfo
      const newFlashPromotion = item.newFlashPromotion || newFlashPromotionInfo?.[item.goods_id]?.newFlashPromotion || newFlashPromotionInfo?.[item.goods_id]?.flashZonePromotion

      if (exclusivePromotionPrice) item.vipPrice = exclusivePromotionPrice
      if (sheinClubPromotionInfo) item.sheinClubPromotionInfo = sheinClubPromotionInfo
      if (newFlashPromotion) item.newFlashPromotion = newFlashPromotion
    })
    return { integrityPromotionInfo, vipPriceList, items: goods }
  }

  // assignItems 的同步版本, 避免产生微任务/宏任务
  assignItemsSync({
    items = [],
    language = this.language || {},
    isGetLable = false,
    isGetVipPrice = false,
    isGetSheinClubPrice = false,
    isAppPromotion = false,
    isGetNewFlashPromotion = false,
    promotionInfoFromServer = {},
  } = {}) {
    const goods = [].concat(items)
    const needRequestIds = []
    const asyncPromotionIds = isGetLable ? FetchPromotionLableTypeIds : NeedFetchPromotionTypeIds
    let notAsyncPromotionintegrityPromotionInfo = {}

    // 遍历取出所有要请求详情的活动id，统一请求
    goods.forEach(item => {
      item.promotionInfo && item.promotionInfo.forEach(info => {
        const { id, typeId, flash_type, flashType } = info
        const newFlashType = flash_type || flashType
        if (asyncPromotionIds.includes(+typeId) && !needRequestIds.includes(id)) {
          needRequestIds.push(id)
        }
        const { goods_id, goods_sn, mall_code } = item
        const param = { goods_id, goods_sn, mall_code }
        // 以下三个都是互斥的（有我而无他）
        if (isGetVipPrice && typeId == 12) {
          // s3 vip会员
          param.promotionId = id
        } else if (isGetSheinClubPrice && typeId == 29) {
          // 付费会员
          param.promotionId = id
        } else if (isGetNewFlashPromotion && typeId == 10 && [2, 3].includes(newFlashType && Number(newFlashType))) {
          // 新型闪购（新人，专属）
          param.promotionId = id
        }
      })
      
      if (isAppPromotion && Array.isArray(item.promotionInfo) && item.appPromotion) {
        item.promotionInfo = item.promotionInfo.concat(item.appPromotion)
      }
    })

    // 促销信息在服务端获取后, 直接存入缓存, 避免再请求接口获取
    this.setCacheIntegrityPromotionInfo(promotionInfoFromServer, language)

    let integrityPromotionInfo = {}
    for (const needRequestId of needRequestIds) {
      integrityPromotionInfo[needRequestId] = this.cache.integrityPromotionInfo[needRequestId]
    }

    integrityPromotionInfo = Object.assign({}, integrityPromotionInfo, notAsyncPromotionintegrityPromotionInfo)

    // 遍历为每个商品加上promotionModule | vipPrice
    goods.forEach(item => {
      item.promotionModule = new Module({ item, integrityPromotionInfo, language })

      let exclusivePromotionPrice = null
      let sheinClubPromotionInfo = null
      let newFlashPromotion = null

      // 1. 优先从已经混入商品数据中取
      if (Object.keys(item.exclusivePromotionPrice || {})?.length) {
        exclusivePromotionPrice = item.exclusivePromotionPrice
      }
      if (Object.keys(item.sheinClubPromotionInfo || {})?.length) {
        sheinClubPromotionInfo = item.sheinClubPromotionInfo
      }
      if (Object.keys(item.newFlashPromotion || {})?.length) {
        newFlashPromotion = item.newFlashPromotion
      }

      // 3. 最终混入商品中
      if (exclusivePromotionPrice) item.vipPrice = exclusivePromotionPrice
      if (sheinClubPromotionInfo) item.sheinClubPromotionInfo = sheinClubPromotionInfo
      if (newFlashPromotion) item.newFlashPromotion = newFlashPromotion
    })

    return { integrityPromotionInfo, vipPriceList: undefined, items: goods }
  }

  setIntegrityPromotionInfo({ ids = [] } = {}) {
    const requestIds = []
    const result = {}
    if (ids && ids.length) {
      ids.forEach((id) => {
        if (this.cache.integrityPromotionInfo[id] === undefined) {
          result[id] = {}
          requestIds.push(id)
        } else {
          result[id] = this.cache.integrityPromotionInfo[id]
        }
      })
    }
    if (!requestIds.length) {
      return Promise.resolve(result)
    }
    return schttp({
      url: '/api/cart/getIntegrityPromotionInfo/get',
      method: 'POST',
      data: {
        ids: ids,
      },
    })
      .then((res) => {
        if (res.code == '0' && res.info && res.info.length > 0) {
          res.info.forEach((item) => {
            this.dealIntegrityPromotionInfo(item)
            result[item.id] = this.cache.integrityPromotionInfo[item.id] = item
          })
        }
        return result
      })
      .catch(() => {
        return result
      })
  }

  dealIntegrityPromotionInfo(info) {
    const integrityContent = {}
    const { isCountdown, endTimestamp } = info
    if (isCountdown == '1' && endTimestamp) {
      integrityContent.countDown = PromotionUtils.countDownTime({
        endTimestamp,
      })
      integrityContent.countDownTimeStamp = endTimestamp * 1000
    }
    info.integrityContent = integrityContent
  }

  /**
   * 获取s3会员、付费会员促销价
   * @param {Array} goodsParams goods_id, goods_sn, mall_code, promotionId
   * @param {Boolean} vipDiscountPrices s3 vip会员
   * @param {Boolean} sheinClubPromotionInfo 付费会员
   */
  async getVipPrice({
    goodsParams = [],
    vipDiscountPrices,
    sheinClubPromotionInfo,
    caller = '',
  }) {
    if (!goodsParams?.length || (!vipDiscountPrices && !sheinClubPromotionInfo))
      return {}

    const atomicInfo = await itemSer.getAtomicInfo({
      goods: goodsParams,
      fields: { vipDiscountPrices, sheinClubPromotionInfo },
      needAssign: false,
      ...(caller ? { caller } : {}),
    })

    return atomicInfo
  }

  /**
   * 获取新型闪购促销信息（新人，专属）
   * @param {Array} flashPromotionParams 入参 goods_id, goods_sn, mall_code, promotionId
   * @param {Array} newFlashPromotion 有新型闪购活动
   * @returns
   */
  async getNewFlashPromotion({ flashPromotionParams, newFlashPromotion, caller }) {
    if (!flashPromotionParams?.length || !newFlashPromotion) return {}
    const atomicInfo = await itemSer.getAtomicInfo({
      goods: flashPromotionParams,
      fields: { 
        newFlashPromotion,
        flashZonePromotion: 1,
      },
      needAssign: false,
      caller
    })
    return atomicInfo
  }

  /**
   * 落地服务端获取促销活动详情信息
   * @param {Object} res 促销信息
   */
  setCacheIntegrityPromotionInfo (res = {}, language = {}) {
    if(Object.keys(language).length){
      this.language = language
    }
    if (res.code == '0' && res.info && res.info.length > 0) {
      res.info.forEach(item => {
        this.dealIntegrityPromotionInfo(item)
        this.cache.integrityPromotionInfo[item.id] = item
      })
    }
  }
}

const promotionCenter =
  typeof window !== 'undefined'
    ? (window.promotionCenter = window.promotionCenter || new PromotionCenter())
    : new PromotionCenter()

export { promotionCenter }
