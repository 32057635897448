import { discountPercentNum } from '@shein/common-function'

function percentNum(num) {
  return `${parseInt(100 - Math.round(100 * num + Number.EPSILON)).toFixed(0)}%`
}

function percentNumWithoutSign(num) {
  return `${parseInt(100 - Math.round(100 * num + Number.EPSILON)).toFixed(0)}`
}

function discountNumCn(num) {
  return `${parseInt(num * 10).toFixed(2) * 1}`
}

function logBrowser(title = '', content = '') {
  console.log(`[PromotionCenter] ${title}`, content)
}

export function percentNumSign (num, responseLocals = null) {
  let newNum = num ? parseInt(num).toFixed(0) : ''
  if(!newNum) return ''
  return discountPercentNum({ val: newNum }, responseLocals)
}

function decimalToPercent(num) {
  let newNum = parseInt(100 - Math.round(100 * num + Number.EPSILON)).toFixed(0)
  return `${newNum}%`
}

function numberToPercentage(num) {
  let newNum = num ? parseInt(num).toFixed(0) : ''
  if(!newNum) return ''
  return `${newNum}%`
}

export default {
  percentNum,
  discountNumCn,
  percentNumWithoutSign,
  logBrowser,
  percentNumSign,
  decimalToPercent,
  numberToPercentage,
}
