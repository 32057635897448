import { PromotionUtils } from '../utils'
import PROMOTION_CONFIG from '../config'
import { template, htmlDecode } from '@shein/common-function'
const { percentNum, percentNumWithoutSign } = PromotionUtils
const { DEBUG } = PROMOTION_CONFIG

/**
 * 添加促销提示语
 * @param {Number} typeId [活动类型id]
 * @param {Object} integrityPromotionInfo [异步获取的活动信息集合]
 * @param {String, Number} id [活动id]
 * @param {Object} language [多语言]
 * @param {Object} content [促销活动整合内容]
 */
function transformPromotionTips ({ language = {}, integrityPromotionInfo = {}, originPromotionInfo = {}, id = '', typeId = '', options = {} }) {
  const content = {}
  const isAsync = Boolean(integrityPromotionInfo[id]) //存在异步数据
  const mixedPromotionInfo = Object.assign({}, integrityPromotionInfo[id] || {}, originPromotionInfo)
  let { rangeList = [], ruleCrondType = '', ruleType = '', buyLimit = '', discountValue = '', singleLimitType = '', singleNum = '', orderNum = '', soldNum = '', endTimestamp = '', mainProductRange, brandName, rules } = mixedPromotionInfo
  const { lang } = gbCommonInfo
  content.id = id // 活动id
  content.typeId = typeId // 活动类型id
  content.tips = { text: '', list: [] } // 列表页tips
  content.tip = '' // 购物车展示的提示语
  content.difference = { text: '', diff: '', diffAmount: null, diffType: '' } // 差额
  content.action = 0 // 0: 无操作; 1: Add，可以添加商品; 2: Pick，可以选择商品
  content.tipsIndex = 0 // 当前使用的提示语index (由于购物车可能有差额提示，导致展示更高一档提示语，此index为当前达到的档次)
  content.lable = '' // 活动标签
  content.rules = rules || [] // 促销样式

  if (DEBUG) {
    PromotionUtils.logBrowser('integrityPromotionInfo', integrityPromotionInfo[id])
  }

  /**
   * 1.买折
   * 差额提示: -
   * 多档次: -
   * @ruleType sale_discount: 按特价折扣; discount: 按原价折(默认)
   */
  if (typeId == 1) {
    if (isAsync) {
      if (ruleType == 'sale_discount') {
        content.tips.list[0] = template(buyLimit, percentNumWithoutSign(discountValue), language.SHEIN_KEY_PC_18177)
      } else {
        content.tips.list[0] = template(buyLimit, percentNumWithoutSign(discountValue), language.SHEIN_KEY_PC_18176)
      }
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_16032

    /**
     * 2.买赠
     * 差额提示: -
     * 多档次: -
     */
  } else if (typeId == 2) {
    if (isAsync) {
      if (mainProductRange == 4) {
        content.tips.list[0] = template(buyLimit, brandName, language.SHEIN_KEY_PC_21328)
      } else {
        content.tips.list[0] = template(buyLimit, language.SHEIN_KEY_PC_21327)
      }
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = mainProductRange == 4 ? template(brandName, language.SHEIN_KEY_PC_21336) : language.SHEIN_KEY_PC_16033

    /**
     * 3.限时活动/折上折
     */
  } else if (typeId == 3) {
    content.tips.text = options.isMoreDiscount ? language.SHEIN_KEY_PC_18042 : language.SHEIN_KEY_PC_15951
    // content.lable = language.SHEIN_KEY_PC_15951

    /**
     * 4.满赠（全场）
     */
  } else if (typeId == 4) {
    content.lable = language.SHEIN_KEY_PC_16033

    /**
     * 8.app专享
     */
  } else if (typeId == 8) {
    content.lable = language.SHEIN_KEY_PC_18000

    /**
     * 9.买N折N
     * 差额提示: -
     * 多档次: 是
     * @ruleType sale_discount: 按特价折扣; discount: 按原价折(默认)
     */
  } else if (typeId == 9) {
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        if (ruleType == 'sale_discount') {
          content.tips.list[i] = template(r.buyLimit.amount, ((1 - r.discountValue.amount) * 100).toFixed(0), language.SHEIN_KEY_PC_17083)
        } else {
          if (['hk', 'tw'].includes(lang)) {
            content.tips.list[i] = template(r.buyLimit.amount, (r.discountValue.amount * 10).toFixed(1), language.SHEIN_KEY_PC_14555)
          } else {
            content.tips.list[i] = template(r.buyLimit.amount, ((1 - r.discountValue.amount) * 100).toFixed(0) + '%', language.SHEIN_KEY_PC_14555?.replace('%', ''))
          }
        }
      })
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_16032

    /**
     * 10.常规闪购 & 11.直播闪购
     * 差额提示: -
     * 多档次: -
     * @singleLimitType 1: 按产品限购; 2: 按订单限购; 3: Both 1 & 2
     * @singleNum 限购数，singleLimitType=1 为按产品限购数，singleLimitType=2 为按订单限购数
     * @ordernum 按订单限购数，仅在 singleLimitType=3 时生效
     * @memberNum 按用户限购数，仅在 memberNum > 0 时生效
     * 场景：
     * 1、单品限购+用户限购singleNum\memberNum有值 2、用户限购 仅memberNum有值
     * 3、订单限购+用户限购orderNum\memberNum有值取小的那个值 4、单品限购+订单限购+用户限购singleNum\orderNum\memberNum都有值，取singleNum+orderNum\memberNum值小的那个
     */
  } else if (typeId == 10 || typeId == 11) {
    if (Object.keys(originPromotionInfo).length) {
      // 购物车场景
      const currentTimestamp = Date.now()
      const tips = {
        1: template(singleNum, language.SHEIN_KEY_PC_17383),
        2: '',
        3: template(singleNum, language.SHEIN_KEY_PC_17383)
      }
      const text = tips[singleLimitType] || ''
      content.tips.list[0] = Number(buyLimit) > Number(soldNum) && currentTimestamp < Number(endTimestamp) ? text : ''
      content.tips.text = content.tips.list[0] || ''
    }

    /**
     * 13.加价购
     * 差额提示: -
     * 多档次: 是
     * @ruleType quantity: 满件加价购; price: 满元加价购(默认)
     * @ruleDimension price: 以固定金额加购; discount: 以折扣加购
     */
  } else if (typeId == 13) {
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        const ruleDimension = r.ruleDimension
        const buyLimitAmountWithSymbol = r.buyLimit.amountWithSymbol
        const buyLimitAmount = r.buyLimit.amount
        const redemptionPriceAmountWithSymbol = r.redemption[0].price.amountWithSymbol
        const redemptionPriceAmount = r.redemption[0].price.amount

        if (ruleType == 'quantity') {
          // 满件
          if (ruleDimension == 'price') {
            content.tips.list[i] = template(buyLimitAmount, redemptionPriceAmountWithSymbol, htmlDecode({ text: language.SHEIN_KEY_PC_17204 })) // 按元换购
          } else if (r.ruleDimension == 'discount') {
            content.tips.list[i] = template(buyLimitAmount, percentNumWithoutSign(redemptionPriceAmount), htmlDecode({ text: language.SHEIN_KEY_PC_17202 })) // 按折扣换购
          }
        } else {
          // 满元
          if (r.ruleDimension == 'price') {
            content.tips.list[i] = template(buyLimitAmountWithSymbol, redemptionPriceAmountWithSymbol, htmlDecode({ text: language.SHEIN_KEY_PC_17205 })) // 按元换购
          } else if (r.ruleDimension == 'discount') {
            content.tips.list[i] = template(buyLimitAmountWithSymbol, percentNumWithoutSign(redemptionPriceAmount), htmlDecode({ text: language.SHEIN_KEY_PC_17203 })) // 按折扣换购
          }
        }
      })
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_15966

    /**
     * 14.满减
     * 差额提示: 是
     * 多档次: 是
     * @isFullShop 1: 全场满减; 0: 选品满减
     * @ruleCrondType 1: 满元减元; ^1: 满件减元
     */
  } else if (typeId == 14) {
    content.lable = language.SHEIN_KEY_PC_16032
    if (content.rules.length) {
      content.rules.forEach((r, i) => {
        let { type, value, value_amount, discount } = r || {}
        let discountAmountWithSymbol = discount?.value_amount?.amountWithSymbol
        let valueAmountWithSymbol = value_amount?.amountWithSymbol
        let ruleMap = {
          '1': template(discountAmountWithSymbol, value, language.SHEIN_KEY_PC_17542), // 满 {value} 件，减 {discountAmountWithSymbol} 元。
          '2': template(value, discountAmountWithSymbol, language.SHEIN_KEY_PC_29568), // 每满 {value} 件，减 {discountAmountWithSymbol} 元。
          '3': template(discountAmountWithSymbol, valueAmountWithSymbol, language.SHEIN_KEY_PC_17541), // 满 {value_amount.amountWithSymbol} 元，减 {discountAmountWithSymbol}。
          '4': template(valueAmountWithSymbol, discountAmountWithSymbol, language.SHEIN_KEY_PC_29567), // 每满 {valueAmountWithSymbol} 元，减 {discountAmountWithSymbol}。
        }
        if (ruleMap[type]) {
          content.tips.list[i] = ruleMap[type]
        }
      })
    }
    if (rangeList.length) {
      content.tips.text = content.tips.list[0] || ''


      // lable 设置
      if (rules.find(obj => obj.type == 4)) {
        // 每满xxx元活动
        content.lable = template(rangeList[0].buyLimit.amountWithSymbol, rangeList[0].discountValue.amountWithSymbol, language.SHEIN_KEY_PC_29567)
      } else if (rules.find(obj => obj.type == 2)) { 
        // 每满xxx件活动
        content.lable = template(rangeList[0].buyLimit.amount, rangeList[0].discountValue.amountWithSymbol, language.SHEIN_KEY_PC_29568)
      } else {
        content.lable = ruleCrondType == 1 ? template(rangeList[0].discountValue.amountWithSymbol, rangeList[0].buyLimit.amountWithSymbol, language.SHEIN_KEY_PC_17541) : template(rangeList[0].discountValue.amountWithSymbol, rangeList[0].buyLimit.amount, language.SHEIN_KEY_PC_17542)
      }
    }

    /**
     * 15.x元n件
     * 差额提示: 是
     * 多档次: 是
     * @ruleCrondType 1: x件n元; 2: 每x件n元
     * @diffType 1: 差元; 2: 差件
     */
  } else if (typeId == 15) {
    content.lable = language.SHEIN_KEY_PC_16032
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        const discountValueAmountWithSymbol = r.discountValue.amountWithSymbol
        const buyLimitAmount = r.buyLimit.amount
        if (ruleCrondType == 1) {
          content.tips.list[i] = template(discountValueAmountWithSymbol, buyLimitAmount, language.SHEIN_KEY_PC_15975)
        } else if (ruleCrondType == 2) {
          content.tips.list[i] = template(discountValueAmountWithSymbol, buyLimitAmount, language.SHEIN_KEY_PC_15976)
        }
      })
      
      content.tips.text = content.tips.list[0] || ''
      content.lable = template(rangeList[0].discountValue.amountWithSymbol, rangeList[0].buyLimit.amount, language.SHEIN_KEY_PC_17543)
    }

    /**
     * 16.每买N减N (减元)
     * 差额提示: -
     * 多档次: -
     */
  } else if (typeId == 16) {
    if (rangeList.length) {
      if (rangeList.length == 2) {
        content.tips.list[0] = template(rangeList[0].discount.amountWithSymbol, rangeList[1].discount.amountWithSymbol, language.SHEIN_KEY_PC_16156)
      } else if (rangeList.length == 1) {
        content.tips.list[0] = template(rangeList[0].discount.amountWithSymbol, language.SHEIN_KEY_PC_16154)
      }

      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_16032

    /**
     * 17.每买N减N (折扣)
     * 差额提示: -
     * 多档次: -
     */
  } else if (typeId == 17) {
    if (rangeList.length) {
      if (rangeList.length == 2) {
        content.tips.list[0] = template(percentNum((100 - rangeList[0].discount.amount * 100).toFixed(0)), percentNum((100 - rangeList[1].discount.amount * 100).toFixed(0)), language.SHEIN_KEY_PC_16155)
      } else if (rangeList.length == 1) {
        content.tips.list[0] = template(percentNum((100 - rangeList[0].discount.amount * 100).toFixed(0)), language.SHEIN_KEY_PC_16153)
      }
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_16032

    /**
     * 18.买n减n（减元）
     * 差额提示: -
     * 多档次: -
     */
  } else if (typeId == 18) {
    if (rangeList.length) {
      if (rangeList.length == 2) {
        content.tips.list[0] = template(rangeList[0].discount.amountWithSymbol, rangeList[1].discount.amountWithSymbol, language.SHEIN_KEY_PC_16156)
      } else if (rangeList.length == 1) {
        content.tips.list[0] = template(rangeList[0].discount.amountWithSymbol, language.SHEIN_KEY_PC_16154)
      }

      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_16032

    /**
     * 19.买n减n（折扣）
     * 差额提示: -
     * 多档次: -
     * @ruleType sale_discount: 按特价折扣; discount: 按原价折(默认)
     */
  } else if (typeId == 19) {
    if (rangeList.length) {
      if (ruleType == 'sale_discount') {
        if (rangeList.length == 2) {
          content.tips.list[0] = template(percentNumWithoutSign(rangeList[0].discount.amount), percentNumWithoutSign(rangeList[1].discount.amount), language.SHEIN_KEY_PC_17085)
        } else if (rangeList.length == 1) {
          content.tips.list[0] = template(percentNumWithoutSign(rangeList[0].discount.amount), language.SHEIN_KEY_PC_17084)
        }
      } else {
        if (rangeList.length == 2) {
          /* diference PC没区分tw\hk */
          content.tips.list[0] = template(percentNum(rangeList[0].discount.amount), percentNum(rangeList[1].discount.amount), language.SHEIN_KEY_PC_16155)
        } else if (rangeList.length == 1) {
          /* diference PC没区分tw\hk */
          content.tips.list[0] = template(percentNum(rangeList[0].discount.amount), language.SHEIN_KEY_PC_16153)
        }
      }
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_16032

    /**
     * 20.订单返券
     * 差额提示: -
     * 多档次: -
     * @ruleType sale_discount: 按特价折扣; discount: 按原价折(默认)
     */
  } else if (typeId == 20) {
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        const coupon = r.coupon
        const buyLimitAmountWithSymbol = r.buyLimit.amountWithSymbol
        const couponPriceSymbol = coupon.priceSymbol

        if (coupon.isFreeShipCoupon != '0' || coupon.isMemberFreeShipCoupon != '0') {
          content.tips.list[i] = template(buyLimitAmountWithSymbol, htmlDecode({ text: language.SHEIN_KEY_PC_16152 }))
        } else if (coupon.couponTypeId == 1) {
          content.tips.list[i] = template(buyLimitAmountWithSymbol, couponPriceSymbol, htmlDecode({ text: language.SHEIN_KEY_PC_16150 }))
        } else {
          content.tips.list[i] = template(buyLimitAmountWithSymbol, couponPriceSymbol, htmlDecode({ text: language.SHEIN_KEY_PC_16150 }))
        }
      })
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_16035

    /**
     * 24.打折限购
     * 差额提示: -
     * 多档次: -
     * @ruleType sale_discount: 按特价折扣; discount: 按原价折; '': 非打折
     * @singleNum 单品限购数
     * @orderNum 订单限购数
     */
  } else if (typeId == 24) {
    if (isAsync) {
      singleNum = Number(singleNum)
      orderNum = Number(orderNum)
      const discountTip = {
        sale_discount: () => {
          return `${language.SHEIN_KEY_PC_17488}${singleNum ? template(Number(singleNum), language.SHEIN_KEY_PC_17489) : ''}${orderNum ? template(Number(orderNum), language.SHEIN_KEY_PC_17493) : ''}`
        },
        no_discount: () => {
          return `${singleNum && orderNum ? template(Number(singleNum), language.SHEIN_KEY_PC_17489) : singleNum ? template(Number(singleNum), language.SHEIN_KEY_PC_17495) : ''}${orderNum ? template(Number(orderNum), language.SHEIN_KEY_PC_17496) : ''}`
        }
      }
      const setRuleType = ruleType == 'no_discount' ? 'no_discount' : 'sale_discount'
      content.tips.list[0] = discountTip[setRuleType]() || ''
      

      content.tips.text = content.tips.list[0] || ''
      content.tip = content.tips.text + content.difference.text
      content.tipsIndex = 0
      content.lable = ruleType == 'no_discount' ? '' : language.SHEIN_KEY_PC_17484
    }

  /**
   * 25.满折
   * 差额提示: 非常规
   * 多档次: 是
   * @ruleType sale_discount: 按特价折扣; discount: 按原价折; '': 非打折
   */
  } else if (typeId == 25) {
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        if (ruleType == 'sale_discount') {
          content.tips.list[i] = template(r.buyLimit.amountWithSymbol, percentNum(r.discountValue.amount), language.SHEIN_KEY_PC_17835)
        } else {
          content.tips.list[i] = template(r.buyLimit.amountWithSymbol, percentNum(r.discountValue.amount), language.SHEIN_KEY_PC_17836)
        }
      })
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PC_16032

  /**
   * 28. 满赠（非全场 有品牌）
   * 差额提示: Spend {0} more on {1} items to earn a FREE GIFT
   * 0 差额 1 品牌
   * mainProductRange：1、选品链接ID 2、指定商品SKC 3、全场商品 4、品牌
   * 多档次: 是
   */
  } else if (typeId == 28) {
    rangeList.forEach((r, i) => {
      if (mainProductRange == 4) {
        content.tips.list[i] = template(brandName, r.buyLimit.amountWithSymbol, language.SHEIN_KEY_PC_21326)
      } else {
        content.tips.list[i] = template(r.buyLimit.amountWithSymbol, language.SHEIN_KEY_PC_21325)
      }
    })
    
    content.lable = mainProductRange == 4 ? template(brandName, language.SHEIN_KEY_PC_21336) : language.SHEIN_KEY_PC_16033
    content.tips.text = content.tips.list[0] || ''
  }
  return content
}

export { transformPromotionTips }
